.carouselButton {
  --size-button: 3.5rem;
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size-button);
  height: var(--size-button);
  cursor: pointer;
  border-radius: 9rem;
  transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  grid-column: 1 / -1 !important;
}

.accent.carouselButton {
  background-color: var(--beige);
  color: var(--sauge);
}

.accent.carouselButton:hover {
  background-color: var(--beige);
}

.accent.carouselButton:active {
  background-color: var(--white);
}

.light.carouselButton {
  color: var(--sauge);
  background-color: var(--beige);
}

.light.carouselButton:hover {
  color: var(--beige);
}

.light.carouselButton:active {
  color: var(--white);
}

.carouselButton:disabled {
  cursor: default !important;
  pointer-events: none;
  color: var(--gray);
  opacity: 0;
}

.carouselButton svg {
  --size: 2.75rem;
  width: var(--size);
  height: var(--size);
}

.carouselButton:disabled svg {
  opacity: 0.3;
}

.right{
  right: var(--spacing-24);
}

.left{
  left: var(--spacing-24);
}

@media (max-width: 1024px) {
  .carouselButton {
    --size-button: 2.5rem;
  }
  .carouselButton svg {
    --size: 2rem;
  }
}
